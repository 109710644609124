<template>
  <q-layout view="hHh lpR fff">
    <!--  --  header --  -->
    <q-header v-bind:style="{ opacity: opacity_header }" class="header-menu" height-hint="98">
      <q-toolbar class="shadow-2 rounded-borders">
        <!--  --  header menu for small displays --  -->
        <q-btn flat round dense icon="menu" class="q-mr-sm lt-lg">
          <q-menu>
            <q-list style="min-width: 300px">
              <q-item clickable v-close-popup @click="setCurrentTab('opener')">
                <q-item-section><q-icon name="home" /></q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setCurrentTab('services')">
                <q-item-section>{{ $t("header.services") }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setCurrentTab('products')">
                <q-item-section>{{ $t("header.products") }}</q-item-section>
              </q-item>
              <!--
              <q-item clickable v-close-popup @click="setCurrentTab('stories')">
                <q-item-section>{{ $t("header.stories") }}</q-item-section>
              </q-item>
            -->
              <q-item clickable v-close-popup @click="setCurrentTab('about')">
                <q-item-section>{{ $t("header.company") }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setCurrentTab('contact')">
                <q-item-section>{{ $t("header.contact") }}</q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-close-popup @click="switchLocalization()">
                <q-item-section>{{ $t("header.localization") }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <!--  --  logo --  -->
        <q-avatar class="lt-md" size="3rem">
          <img src="./assets/logo.png">
        </q-avatar>
        <q-avatar class="gt-sm" size="5rem">
          <img src="./assets/logo.png">
        </q-avatar>
        <div class="blue">DIS</div>TECH<div class="sub-header-menu">.Systems</div>
        <q-space />
        <!--  --  header menu for large displays --  -->
        <q-tabs v-model="tab" class="gt-md">
          <q-tab name="opener" icon="home" @click="setCurrentTab('opener')" />
          <q-separator vertical inset />
          <q-tab name="services" :label="$t('header.services')" @click="setCurrentTab('services')" />
          <q-separator vertical inset />
          <q-tab name="products" :label="$t('header.products')" @click="setCurrentTab('products')" />
          <!--
          <q-separator vertical inset />
          <q-tab name="stories" :label="$t('header.stories')" @click="setCurrentTab('services')" />
        -->
          <q-separator vertical inset />
          <q-tab name="about" :label="$t('header.company')" @click="setCurrentTab('about')" />
          <q-separator vertical inset />
          <q-tab name="contact" :label="$t('header.contact')" @click="setCurrentTab('contact')" />
          <q-separator vertical inset />
        </q-tabs>
        <q-btn class="gt-md" flat @click="switchLocalization()">EN / DE</q-btn>
      </q-toolbar>
    </q-header>

    <!--  --  page content --  -->
    <q-page-container>
      <RouterView :contact="contact" @setCurrentTabProducts="setCurrentTab('products')"
        @setCurrentTabProductsRaptor="setCurrentTab('products#raptor')"
        @setCurrentTabProductsMmps="setCurrentTab('products#mmps')"
        @setCurrentTabProductsXlms="setCurrentTab('products#xlms')"
        @setCurrentTabServices="setCurrentTab('services')" />
      <!-- contact form -->
      <q-page v-if="tab == 'opener' || tab == 'products' || tab == 'stories' || tab == 'services'">
        <q-separator inset />
        
        <div class="q-pa-md q-gutter-md" align="center">
          <q-card class="q-pa-md my-card" bordered>
            <div class="q-mt-md services-text-description" align="center">{{ $t("opener.contact") }}</div>
            <q-inner-loading :showing="showLoadingInContactForm" :label="$t('contact.sending')"
              label-class="text-regular text-primary" label-style="font-size: 1.1em" color="primary" />
            <q-input v-model="contact.company.value" :disable="showLoadingInContactForm" @click="messageSent = false"
              :label="$t('contact.company')">
              <template v-slot:prepend>
                <q-icon name="apartment" />
              </template>
            </q-input>
            <q-input v-model="contact.name.value" :disable="showLoadingInContactForm" @click="messageSent = false"
              ref="nameRef" :rules="nameRules" :label="$t('contact.name')">
              <template v-slot:prepend>
                <q-icon name="badge" />
              </template>
            </q-input>
            <q-input v-model="contact.email.value" :disable="showLoadingInContactForm" @click="messageSent = false"
              type="email" ref="emailRef" lazy-rules :rules="emailRules" :label="$t('contact.email')">
              <template v-slot:prepend>
                <q-icon name="alternate_email" />
              </template>
            </q-input>
            <q-input v-model="contact.message.value" :disable="showLoadingInContactForm" @click="messageSent = false"
              clearable type="textarea" ref="messageRef" lazy-rules :rules="messageRules" :label="$t('contact.message')"
              :hint="$t('contact.hint')">
              <template v-slot:prepend>
                <q-icon name="chat" />
              </template>
            </q-input>
            <div class="q-mt-md" align="right">
              <q-btn v-if="!messageSent" color="primary" icon-right="send" :label="$t('contact.send')"
                :disable="showLoadingInContactForm" @click="sendContactForm" />
              <q-btn v-if="messageSent" color="green" icon-right="check" :label="$t('contact.sent')" />
            </div>
          </q-card>
        </div>
        <q-page-scroller position="bottom-right" :scroll-offset="300" :offset="[18, 18]">
          <q-btn fab icon="keyboard_arrow_up" color="primary" />
        </q-page-scroller>
      </q-page>
    </q-page-container>

    <!--  --  footer --  -->
    <q-footer elevated class="bg-grey-9 text-white">
      <q-toolbar>
        <div style="position: flex; width: 100%;" align="center">
          <q-avatar class="lt-md" size="7rem">
            <img src="./assets/logo.png">
          </q-avatar>
          <q-avatar class="gt-sm" size="10rem">
            <img src="./assets/logo.png">
          </q-avatar>
          <br /><a @click='imprint = true' class="cursor-pointer">{{ $t("footer.imprint.name") }}</a> |
          <a @click='privacyPolicy = true' class="cursor-pointer">{{ $t("footer.privacyPolicy.name") }}</a> |
          <a @click='termsAndConditions = true' class="cursor-pointer">{{ $t("footer.termsAndConditions.shortName")
            }}</a>
          <br />© 2025 Distech.Systems GmbH All rights reserved.
        </div>
      </q-toolbar>
    </q-footer>
  </q-layout>

  <!--  --  legal texts --  -->
  <q-dialog v-model="imprint">
    <q-card>
      <q-card-section class="row items-center">
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-separator />
      <q-card-section style="max-height: 70vh" class="scroll">
        <div class="text-h5 text-bold q-mb-sm">{{ $t("footer.imprint.name") }}</div>
        <div class="text-bold">Sitz der Gesellschaft / Registered Office</div>
        <div class="text-light">DISTECH Systems GmbH<br />Südstrasse 7<br />D-59439 Holzwickede<br />Tel.: +49 2301
          91323 0<br />Fax: +49 2301 91323 99<br />E-Mail: office@distech.systems</div>
        <q-separator class="q-ma-sm" />
        <div class="text-bold">Lieferadresse / Delivery Address</div>
        <div class="text-light">Gottlieb-Daimler-Strasse 12<br />D-59439 Holzwickede</div>
        <q-separator class="q-ma-sm" />
        <q-list separator>
          <q-item>
            <q-item-section class="text-bold">Geschäftsführer / Managing Director</q-item-section>
            <q-item-section class="text-light">Guido Elbrecht</q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="text-bold">Handelsregister / Commercial Register</q-item-section>
            <q-item-section class="text-light">Hamm, HRB 10286</q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="text-bold">Steuernummer / Tax No.</q-item-section>
            <q-item-section class="text-light">316/5718/1090</q-item-section>
          </q-item>
          <q-item>
            <q-item-section class="text-bold">UID / VAT</q-item-section>
            <q-item-section class="text-light">DE338089604</q-item-section>
          </q-item>
        </q-list>
        <div class="text-bold"></div>
        <q-separator class="q-mt-sm q-mb-md" />
        <div class="text-h5 text-bold q-mb-sm">{{ $t("footer.disclaimer.name") }}</div>
        <div class="text-bold">{{ $t("footer.disclaimer.paragraph1.header") }}</div>
        <div class="text-light">{{ $t("footer.disclaimer.paragraph1.text") }}<br /><br /></div>
        <div class="text-bold">{{ $t("footer.disclaimer.paragraph2.header") }}</div>
        <div class="text-light">{{ $t("footer.disclaimer.paragraph2.text") }}<br /><br /></div>
        <div class="text-bold">{{ $t("footer.disclaimer.paragraph3.header") }}</div>
        <div class="text-light">{{ $t("footer.disclaimer.paragraph3.text") }}<br /><br /></div>
      </q-card-section>
      <q-separator />
    </q-card>
  </q-dialog>

  <q-dialog v-model="privacyPolicy">
    <q-card>
      <q-card-section class="row items-center">
        <div class="text-h5 text-bold">{{ $t("footer.privacyPolicy.name") }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>
      <q-separator />
      <q-card-section style="max-height: 70vh" class="scroll">
        <div class="text-bold">{{ $t("footer.privacyPolicy.paragraph1.header") }}</div>
        <div class="text-light">{{ $t("footer.privacyPolicy.paragraph1.text") }}<br /><br /></div>
        <div class="text-bold">{{ $t("footer.privacyPolicy.paragraph2.header") }}</div>
        <div class="text-light">{{ $t("footer.privacyPolicy.paragraph2.text") }}<br /><br /></div>
        <div class="text-bold">{{ $t("footer.privacyPolicy.paragraph3.header") }}</div>
        <div class="text-light">{{ $t("footer.privacyPolicy.paragraph3.text") }}<br /><br /></div>
      </q-card-section>
      <q-separator />
    </q-card>
  </q-dialog>

  <q-dialog v-model="termsAndConditions">
    <q-card>
      <q-card-section>
        <q-item>
          <q-item-section>
            <div class="text-h5 text-bold">{{ $t("footer.termsAndConditions.name") }}</div>
          </q-item-section>
          <q-item-section side class="text-black"><q-btn side icon="close" flat round dense
              v-close-popup /></q-item-section>
        </q-item>
      </q-card-section>
      <q-separator />
      <q-card-section style="max-height: 70vh" class="scroll">
        <div class="text-light" v-html="$t('footer.termsAndConditions.text')" />
      </q-card-section>
      <q-separator />
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useI18n } from 'vue-i18n';
import emailjs from 'emailjs-com';
import router from './router';

export default {
  name: 'LayoutDefault',

  setup() {
    const { locale } = useI18n()
    const showHeader = ref(false)
    const opacity_header = ref(0.0)
    const tab = ref('opener')
    const contact = { company: ref(''), name: ref(''), email: ref(''), message: ref('') }
    const nameRules = [val => (val && val.length > 0) || 'Please type your name']
    const emailRules = [val => (val && val.length > 0 && (val.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/g) || []).length == 1) || 'Please type an email address']
    const messageRules = [val => (val && val.length > 5) || 'Please type a message']
    const nameRef = ref()
    const emailRef = ref()
    const messageRef = ref()
    const messageSent = ref(false)
    const showLoadingInContactForm = ref(false)

    watch(router.currentRoute, (newRoute) => {
      //Router names defined in ./router/index.js must match with tab-names
      tab.value = newRoute.name
      if (newRoute.name !== 'opener') {
        if (newRoute.hash === "") {
          opacity_header.value = 1.0
        }
      }
      else {
        opacity_header.value = 0.0
      }
    })

    function sendContactForm() {
      nameRef.value.validate()
      emailRef.value.validate()
      messageRef.value.validate()

      if (nameRef.value.hasError || emailRef.value.hasError || messageRef.value.hasError) {
        // form has error
      }
      else {
        showLoadingInContactForm.value = true
        emailjs
          .send("service_0lhk5sr", "template_b2q54fq", {
            company: contact.company.value,
            name: contact.name.value,
            email: contact.email.value,
            message: contact.message.value,
          }, "F9rfNeowadLbglT-d")
          .then(
            (result) => {
              console.log(
                "You have successfully submitted your message",
                result.text
              );
              contact.company.value = ""
              contact.name.value = ""
              contact.email.value = ""
              contact.message.value = ""
              messageSent.value = true
              showLoadingInContactForm.value = false
              setTimeout(() => {

                nameRef.value.resetValidation()
                emailRef.value.resetValidation()
                messageRef.value.resetValidation()
              }, 500)
            },
            (error) => {
              console.log(
                "This form failed to submit, please kindly check your internet connection",
                error.text
              );
              showLoadingInContactForm.value = false
            }
          );
      }
    }

    function setCurrentTab(currentTab) {
      //tab will be set in watch(router.currentRoute
      //tab.value = currentTab;
      if (currentTab == 'opener') {
        router.push('/')
      }
      else {
        router.push('/' + currentTab)
      }
    }

    function handleScroll() {
      opacity_header.value = tab.value == 'opener' && window.scrollY < 100 ? 0.0 : 1.0;
    }

    function switchLocalization() {
      locale.value = locale.value == 'de' ? 'en' : 'de';
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      showHeader,
      opacity_header,
      tab,
      handleScroll,
      setCurrentTab,
      switchLocalization,
      imprint: ref(false),
      privacyPolicy: ref(false),
      termsAndConditions: ref(false),
      contact,
      sendContactForm,
      nameRules,
      emailRules,
      messageRules,
      nameRef,
      emailRef,
      messageRef,
      messageSent,
      showLoadingInContactForm
    }
  }
}
</script>
